<template>
  <StepComponent>
    <v-row>
      <v-col>
        <v-autocomplete
          :items="sortedActiveLabs"
          :filter="customFilter"
          :rules="rules.required"
          label="Lab"
          no-data-text="No matches were found"
          item-text="title"
          item-value="id"
          v-model="lab_id"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "@/utils/rules";

export default {
  name: "LabSelection",
  components: { StepComponent },
  data() {
    return {
      rules: {
        required: [rules.required]
      }
    };
  },
  computed: {
    ...mapGetters("baseData", ["sortedActiveLabs"]),
    ...mapGetters("clients", ["selectedClient"]),
    ...mapFields("clients", ["client.lab_id"])
  },
  methods: {
    ...mapActions("clients", ["updateClientLab"]),
    customFilter(item, queryText) {
      const title = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();

      return title.indexOf(searchText) > -1;
    },
    beforeContinue() {
      return this.updateClientLab().catch(e => {
        console.error(e);
        return false;
      });
    }
  },
  beforeMount() {
    if (!this.lab_id && this.selectedClient && this.selectedClient.lab_id)
      this.lab_id = this.selectedClient.lab_id;
  }
};
</script>

<style scoped></style>
