<template>
  <StepComponent>
    <v-row align-content="center" justify="center">
      <v-col>
        <div>Receive Invoices Via</div>
        <template v-for="communication_type in filteredCommunicationTypes">
          <v-list-item
            v-if="communication_type.name === 'Fax'"
            :key="communication_type.id"
          >
            <v-tooltip right :disabled="!!fax">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                  <v-checkbox
                    :label="communication_type.name"
                    :value="communication_type.id"
                    v-model="invoice_communication_types_selected"
                    :disabled="!!!fax"
                    :rules="rules.invoice_term"
                  />
                </div>
              </template>
              <span>
                A fax number was not entered during the contact information
                step.
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-else :key="communication_type.id">
            <v-checkbox
              :label="communication_type.name"
              :value="communication_type.id"
              v-model="invoice_communication_types_selected"
              :rules="rules.invoice_term"
            />
          </v-list-item>
        </template>
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/rules";

export default {
  name: "InvoiceTerms",
  components: { StepComponent },
  data() {
    return {
      rules: {
        invoice_term: [
          rules.required,
          v => (v && v.length >= 1) || `Must select at least one`
        ]
      },
      valid: true
    };
  },
  computed: {
    ...mapFields("clients", [
      "items[0].fax",
      "items[0].invoice_communication_types",
      "invoice_communication_types_selected"
    ]),
    ...mapGetters("baseData", ["communication_types"]),
    filteredCommunicationTypes() {
      return this.communication_types.filter(item => !!item.is_invoice);
    },
  },
  methods: {
    ...mapActions("clients", ["updateClientInvoiceTypes"]),
    beforeContinue() {
      return this.updateClientInvoiceTypes().catch(e => {
        console.error(e);
        return false;
      });
    }
  }
};
</script>

<style scoped></style>
