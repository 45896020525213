<template>
  <BaseCard
    title="Reporting Preferences"
    :step-component="'ReportingPreferencesStep'"
  >
    <v-row>
      <v-col cols="12" class="pb-0">
        Receive Reports Via: {{ reportTypes }}
      </v-col>
      <v-col cols="12" class="pb-0" v-if="reportLayouts.length">
        Report Layouts: {{ reportLayouts }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Language:
        {{ getById("locales", selectedClient.locale_code, "code").name }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Units: {{ getById("unit_types", selectedClient.unit_type_id).name }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Hold Report for Entire Package:
        {{ selectedClient.release_full_package ? "Yes" : "No" }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Merge Reports by Farm Name:
        {{ selectedClient.merge_reports_by_farm ? "Yes" : "No" }}
      </v-col>
      <v-col cols="12" class="pb-0" v-if="reportTemplates.length">
        Reports: {{ reportTemplates }}
      </v-col>
      <v-col cols="12" v-if="reportTemplateTypes.length">
        Specialty Reports: {{ reportTemplateTypes }}
      </v-col>
    </v-row>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/registration/ReviewStepCards/BaseCard";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ReportingPreferences",
  components: { BaseCard },
  computed: {
    ...mapState("clients", [
      "report_communication_types_selected",
      "report_layouts_selected",
      "report_templates_selected",
      "report_template_types_selected"
    ]),
    ...mapGetters("clients", ["selectedClient"]),
    ...mapGetters("baseData", ["getById"]),
    reportTypes() {
      return (
        this.report_communication_types_selected
          .reduce((output, type) => {
            output +=
              (this.getById("communication_types", type) || {}).name + ", " ||
              "";
            return output;
          }, "")
          // Trim whitespace
          .trim()
          // Trim commas
          .replace(/(^,)|(,$)/g, "")
      );
    },
    reportLayouts() {
      return (
        this.report_layouts_selected
          .reduce((output, type) => {
            output +=
              (this.getById("report_layouts", type) || {}).title + ", " || "";
            return output;
          }, "")
          // Trim whitespace
          .trim()
          // Trim commas
          .replace(/(^,)|(,$)/g, "")
      );
    },
    reportTemplates() {
      return (
        this.report_templates_selected
          .reduce((output, type) => {
            output +=
              (this.getById("report_templates", type) || {}).title + ", " || "";
            return output;
          }, "")
          // Trim whitespace
          .trim()
          // Trim commas
          .replace(/(^,)|(,$)/g, "")
      );
    },
    reportTemplateTypes() {
      return (
        this.report_template_types_selected
          .reduce((output, type) => {
            output +=
              (this.getById("report_template_types", type) || {}).name + ", " ||
              "";
            return output;
          }, "")
          // Trim whitespace
          .trim()
          // Trim commas
          .replace(/(^,)|(,$)/g, "")
      );
    }
  }
};
</script>

<style scoped></style>
