<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-stepper v-model="currentStep" vertical>
      <v-stepper-items>
        <template v-for="(step, n) in steps">
          <v-stepper-step
            :key="`step-${n}`"
            :complete="currentStep > n"
            :step="n + 1"
          >
            {{ step.title }}
          </v-stepper-step>
          <v-stepper-content :key="`step-content-${n}`" :step="n + 1">
            <component
              v-bind:is="step.component"
              :ref="step.component"
              v-if="currentStep === n + 1"
            />
            <v-row no-gutters>
              <v-col></v-col>
            </v-row>
            <div class="v-footer no-gutters" v-if="displaySubmit()">
              <LoadingBar v-if="loading === true" />
              <template v-else>
                <v-btn
                  v-if="currentStep > 1"
                  color="primary"
                  @click="previousStep"
                  class="mb-1"
                >
                  Previous
                </v-btn>
                <v-layout align-center justify-center>
                  Client Portal access will be limited until your new account is approved.
                </v-layout>
                <v-btn
                  color="primary"
                  @click="nextStep(n + 1)"
                  :disabled="!canContinue"
                  class="mb-1"
                >
                  {{ currentStep === steps.length ? "Submit" : "Continue" }}
                </v-btn>
              </template>
            </div>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
import AccountInformationStep from "@/components/registration/AccountSetupSteps/AccountInformationStep";
import ContactInformationStep from "@/components/registration/AccountSetupSteps/ContactInformationStep";
import AddressStep from "@/components/registration/AccountSetupSteps/AddressStep";
import LabSelectionStep from "@/components/registration/AccountSetupSteps/LabSelectionStep";
import ReportingPreferencesStep from "@/components/registration/AccountSetupSteps/ReportingPreferencesStep";
import InvoiceTermsStep from "@/components/registration/AccountSetupSteps/InvoiceTermsStep";
import ReviewStep from "@/components/registration/AccountSetupSteps/ReviewStep";

/**
 * @property {int} currentStep
 */
export default {
  name: "AccountStepWizard",
  components: {
    LoadingBar,
    AccountInformationStep,
    ContactInformationStep,
    AddressStep,
    LabSelectionStep,
    ReportingPreferencesStep,
    InvoiceTermsStep,
    ReviewStep
  },
  data() {
    return {
      loading: false,
      valid: false // This gets updated automatically, via the validator
    };
  },
  computed: {
    ...mapGetters("registration", ["steps"]),
    ...mapFields("registration", ["wizard.currentStep", "wizard.returnStep"]),
    ...mapFields("auth", ["user.roles"]),
    // Returns the current step component instance
    currentStepComponent() {
      if (!this.$refs) return undefined;
      // Find the current component (by ref="componentName")
      // currentStep is ahead of the index by 1
      let component = this.$refs[this.steps[this.currentStep - 1].component];
      if (!component) return undefined;
      if (Array.isArray(component)) {
        // The component will most likely be in an array, but it'll only have one item in it
        component = component[0];
      }
      return component;
    },
    canContinue() {
      return this.valid;
    }
  },
  methods: {
    ...mapActions("clients", ["getClients"]),
    ...mapActions("baseData", [
      "getCommunicationTypes",
      "getLocales",
      "getUnitTypes",
      "getLabs",
      "getReportLayouts",
      "getReportTemplates",
      "getReportTemplateTypes"
    ]),
    previousStep() {
      this.currentStep = this.currentStep - 1;
    },
    // Proceed to the next step
    nextStep(n) {
      // If validation doesn't succeed, we can stop now
      if (!this.$refs.form.validate()) return false;
      // If component has beforeContinue method, execute it. Otherwise, we can safely set to true.
      const beforeContinue =
        this.currentStepComponent && this.currentStepComponent.beforeContinue
          ? this.currentStepComponent.beforeContinue()
          : true;
      // Resolve as a promise, that way we can do async things when needed
      Promise.resolve(beforeContinue).then(res => {
        // As long as we don't have a false response, we can proceed to the next step
        if (res) {
          // If returnStep contains a step, go to that step
          if (this.returnStep && typeof this.returnStep === "number") {
            // this.currentStep = this.returnStep;
            this.currentStep = this.steps.length; // Use last step every time for now (to resolve dynamic steps issues)
            this.returnStep = false; // Reset returnStep value
            return true;
          }
          // Default to next step iteration
          this.currentStep = n + 1;

          if (this.currentStep > this.steps.length) {
            if (!this.isRoleClient()) {
              this.$router.push("/setup/confirm");
            }
          }
        }
      });
    },
    isRoleClient() {
      return this.roles.includes("client") ? true : false;
    },
    displaySubmit() {
      if (this.currentStep === this.steps.length) {
        if (this.isRoleClient()) {
          return false;
        }
      }

      return true;
    }
  },
  mounted() {
    this.loading = true;
    // If approved client, load review for account management
    if (this.isRoleClient()) {
      this.currentStep = this.steps.length;
    }

    Promise.all([
      this.getClients(),
      this.getCommunicationTypes(),
      this.getLocales(),
      this.getUnitTypes(),
      this.getLabs(),
      this.getReportLayouts(),
      this.getReportTemplates(),
      this.getReportTemplateTypes()
    ]).then(() => (this.loading = false));
  }
};
</script>
